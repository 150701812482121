"use client"
import dynamic from "next/dynamic"
import SkeletonLoader from "./_components/skelton-accessories"

const DynamicAccessories = dynamic(
  () => import("./accessories").then((mod) => mod.Accessories),
  { ssr: false, loading: () => <SkeletonLoader /> }
)

export default function AccessoriesWrapper() {
  return <DynamicAccessories />
}
