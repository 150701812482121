"use client"
import dynamic from "next/dynamic"
import SkeletonLoader from "./skeleton-best-deals"

const DynamicBestDeals = dynamic(
  () => import("./best-deals").then((mod) => mod.BestDeals),
  { ssr: false, loading: () => <SkeletonLoader /> }
)

export default function BestDealsWrapper() {
  return <DynamicBestDeals />
}
