"use client"
import dynamic from "next/dynamic"
import SkeletonLoader from "./skeleton-top-picks"

const DynamicTopPicks = dynamic(
  () => import("./top-picks").then((mod) => mod.TopPicks),
  { ssr: false, loading: () => <SkeletonLoader /> }
)

export default function TopPicksWrapper() {
  return <DynamicTopPicks />
}
