"use client"
import dynamic from "next/dynamic"
import { ImageComparisonSkeleton } from "./img-comparison"

const DynamicImgComparison = dynamic(
  () => import("./img-comparison").then((mod) => mod.ImageComparison),
  { ssr: false, loading: () => <ImageComparisonSkeleton /> }
)

export default function ImgComparisonWrapper() {
  return <DynamicImgComparison />
}
