"use client"
import dynamic from "next/dynamic"
import { BentoGridSkeleton } from "./_component/bento-skeleton"

const DynamicBentoGrid = dynamic(
  () => import("./bento-grid").then((mod) => mod.default),
  { ssr: false, loading: () => <BentoGridSkeleton /> }
)

export default function BentoGridWrapper() {
  return <DynamicBentoGrid />
}
