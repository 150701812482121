"use client"
import dynamic from "next/dynamic"
import { WatchAndShopSkeleton } from "./watch-and-shop"

const DynamicWatchAndShop = dynamic(
  () => import("./watch-and-shop").then((mod) => mod.WatchAndShop),
  { ssr: false, loading: () => <WatchAndShopSkeleton /> }
)

export default function WatchAndShopWrapper() {
  return <DynamicWatchAndShop />
}
