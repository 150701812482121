"use client"
import dynamic from "next/dynamic"

const DynamicBlogs = dynamic(
  () => import("./blogs").then((mod) => mod.default),
  { ssr: false }
)

export default function BlogsWrapper() {
  return <DynamicBlogs />
}
