
import dynamic from "next/dynamic"
import { Skeleton } from "@/components/ui/skeleton"

const NoSSRCarousel = dynamic(() => import("@/components/ui/motion-carousel").then(mod => mod.Carousel), {
  ssr: false,
  loading: () => <ImageCarouselSkeleton />,
})
const NoSSRCarouselContent = dynamic(() => import("@/components/ui/motion-carousel").then(mod => mod.CarouselContent), {
  ssr: false,
  loading: () => <ImageCarouselSkeleton />,
})
const NoSSRCarouselIndicator = dynamic(() => import("@/components/ui/motion-carousel").then(mod => mod.CarouselIndicator), {
  ssr: false,
  loading: () => <ImageCarouselSkeleton />,
})
const NoSSRCarouselItem = dynamic(() => import("@/components/ui/motion-carousel").then(mod => mod.CarouselItem), {
  ssr: false,
  loading: () => <ImageCarouselSkeleton />,
})

import Link from "next/link"

export function ImageCarouselSkeleton() {
  
  return (
    <div className="flex justify-center items-center mt-16 mb-16 sm:mt-0 w-full">
      <div className="w-full xl:max-w-full">
        <div className="flex overflow-hidden">
          {[...Array(1)].map((_, i) => (
            <div
              key={i}
              className="flex justify-center px-3 lg:px-6 w-full min-h-[200px] lg:min-h-[300px]"
            >
              <Skeleton className="w-full h-full rounded-2xl" />
            </div>
          ))}
        </div>

        {/* Carousel Indicators */}
        <div className="flex justify-center items-center mt-4 space-x-2">
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} className="h-2 w-2 rounded-full bg-primary/20" />
          ))}
        </div>
      </div>
    </div>
  )
}

export function ImageCarousel() {
  const banners = [
    "/media/slider/banner1.webp",
    "/media/slider/banner2.webp",
    "/media/slider/banner3.webp",
  ]

  return (
    <div className="flex justify-center items-center mt-16 sm:mt-0 ">
      <NoSSRCarousel
        autoplay
        autoplayInterval={2500}
        className="w-full xl:max-w-full"
      >
        <NoSSRCarouselContent>
          {banners?.map((banner, i) => (
            <NoSSRCarouselItem
              key={`${banner}-${i}`}
              className="flex justify-center px-3 lg:px-6"
            >
              <Link href={"/all-products"}>
                <img
                  src={banner}
                  alt={`banner-${i}`}
                  className="object-contain w-full h-full rounded-2xl"
                  loading="lazy"
                  fetchPriority="low"
                />
              </Link>
            </NoSSRCarouselItem>
          ))}
        </NoSSRCarouselContent>
        <NoSSRCarouselIndicator />
      </NoSSRCarousel>
    </div>
  )
}
