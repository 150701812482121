function BentoGridSkeleton() {
  return (
    <>
      {/* Desktop View Skeleton */}
      <div className="gap-4 xl:gap-6 hidden sm:flex justify-center items-center">
        <div className="w-[40%] flex flex-col sm:gap-4 xl:gap-6">
          {/* Upper Block Skeleton */}
          <div className="bg-gray-200 rounded-[31px] p-10 h-[150px] animate-pulse"></div>

          {/* Below Block Skeleton */}
          <div className="flex items-center xl:gap-8 sm:gap-4">
            <div className="w-[40%] bg-gray-300 rounded-[19px] h-[120px] animate-pulse"></div>
            <div className="w-[60%] bg-gray-200 rounded-[33px] p-4 h-[150px] animate-pulse"></div>
          </div>
        </div>

        {/* Right Side Skeleton */}
        <div className="w-[50%] flex flex-col gap-4 xl:gap-6">
          <div className="flex gap-4 xl:gap-6 h-[55%]">
            <div className="w-1/2 bg-gray-300 rounded-[33px] p-4 animate-pulse"></div>
            <div className="w-1/2 flex flex-col justify-between">
              <div className="bg-gray-300 h-16 rounded-md animate-pulse"></div>
              <div className="bg-gray-200 rounded-[20px] p-4 h-[100px] animate-pulse"></div>
            </div>
          </div>
          <div className="bg-gray-100 rounded-[31px] p-6 h-[150px] animate-pulse"></div>
        </div>
      </div>

      {/* Mobile View Skeleton */}
      <div className="sm:hidden flex-col mb-20 justify-center items-center">
        <div className="bg-gray-300 rounded-[21px] p-4 h-[100px] animate-pulse"></div>
        <div className="flex items-center gap-4 py-4">
          <div className="bg-gray-300 rounded-[19px] p-8 w-[40%] h-[120px] animate-pulse"></div>
          <div className="flex flex-col w-[60%] gap-2">
            <div className="bg-gray-300 h-8 rounded animate-pulse"></div>
            <div className="bg-gray-300 h-4 rounded animate-pulse"></div>
            <div className="bg-black text-white text-center h-10 rounded animate-pulse"></div>
          </div>
        </div>
        <div className="bg-gray-200 rounded-[22px] p-4 h-[100px] animate-pulse"></div>
        <div className="flex justify-between gap-3 py-3">
          <div className="w-1/2 bg-gray-300 rounded-[19px] p-3 h-[120px] animate-pulse"></div>
          <div className="w-1/2 bg-gray-300 rounded-[19px] p-3 h-[120px] animate-pulse"></div>
        </div>
      </div>
    </>
  )
}

export { BentoGridSkeleton }
