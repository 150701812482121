"use client"
import dynamic from "next/dynamic"

const DynamicBrandsSecx = dynamic(
  () => import("./brands-section").then((mod) => mod.Brands),
  { ssr: false }
)

export default function BrandsSecxWrapper() {
  return <DynamicBrandsSecx />
}
