"use client"
import dynamic from "next/dynamic"
import SkeletonLoader from "./skeleton-latest-release"

const DynamicLatestRelease = dynamic(
  () => import("./latest-release").then((mod) => mod.LatestRelease),
  { ssr: false, loading: () => <SkeletonLoader cardCount={5} /> }
)

export default function LatestReleaseWrapper() {
  return <DynamicLatestRelease />
}
