import React from "react"
import { cn } from "@/lib/utils" // Adjust the import path as per your project structure

interface HeadingProps {
  heading: string
  className?: string // Allow additional classes
}

function Heading({ heading, className }: HeadingProps) {
  return (
    <h2
      className={cn(
        "font-orange text-c text-2xl lg:text-4xl mt-5 lg:mt-1 mb-4 lg:mb-12",
        className
      )}
    >
      {heading}
    </h2>
  )
}

export default Heading
