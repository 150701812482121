"use client"
import dynamic from "next/dynamic"
import { ImageCarouselSkeleton } from "./img-carousel"

const DynamicImgCarousel = dynamic(
  () => import("./img-carousel").then((mod) => mod.ImageCarousel),
  { ssr: false, loading: () => <ImageCarouselSkeleton /> }
)

export default function ImageCarouselWrapper() {
  return <DynamicImgCarousel />
}
