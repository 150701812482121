"use client"
import dynamic from "next/dynamic"
import SkeletonLoader from "../latest-release/skeleton-latest-release"

const DynamicBestSellersSecx = dynamic(
  () => import("./best-sellers").then((mod) => mod.BestSellers),
  {
    ssr: false,
    loading: () => <SkeletonLoader cardCount={5} />,
  }
)

export default function BestSellersSecxWrapper() {
  return <DynamicBestSellersSecx />
}
