import Heading from "@/components/common/re-usable-components/heading"
import { VideoCard } from "./video-card"
import { Skeleton } from "@/components/ui/skeleton"

const videos = [
  "/media/videos/video-1.mp4",
  "/media/videos/video-2.mp4",
  "/media/videos/video-3.mp4",
  "/media/videos/video-4.mp4",
  "/media/videos/video-1.mp4",
]

export const WatchAndShop = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Heading heading="Watch and Shop" />

      <div className="flex justify-start lg:justify-around gap-4 lg:gap-7 items-center w-full h-full overflow-x-auto px-3">
        {videos.map((vid, i) => (
          <VideoCard key={`${vid}-${i}`} src={vid} />
        ))}
      </div>
    </div>
  )
}

export const WatchAndShopSkeleton = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Heading heading="Watch and Shop" />

      <div className="flex justify-start lg:justify-around gap-4 lg:gap-7 items-center w-full h-full overflow-x-auto px-3">
        {[1, 2, 24, 5].map((vid, i) => (
          <Skeleton key={`${vid}-${i}`} className="w-60 h-80 rounded-lg" />
        ))}
      </div>
    </div>
  )
}
