"use client"
import dynamic from "next/dynamic"
import SkeletonLoader from "../latest-release/skeleton-latest-release"

const DynamicExploreProducts = dynamic(
  () => import("./exp-products").then((mod) => mod.ExploreProducts),
  { ssr: false, loading: () => <SkeletonLoader cardCount={10} /> }
)

export default function ExploreProductsWrapper() {
  return <DynamicExploreProducts />
}
